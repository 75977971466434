@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap");

body {
  background-color: #30336b;
  color: #ffffff;
  overflow-x: hidden !important;
}

* {
  font-family: Mukta !important;
}

h4 {
  margin: 0;
}

.hide-mobile {
  display: block;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}
