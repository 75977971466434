@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{background-color:#30336b;color:#fff;overflow-x:hidden !important}*{font-family:Mukta !important}h4{margin:0}.hide-mobile{display:block}@media(max-width: 768px){.hide-mobile{display:none}}
